import { mixins } from "@/plugins/mixins";
export default {
  name: "customerCoupon",
  components: {},
  mixins: [mixins],
  data() {
    return {
      searchItems: [{
        prop: 'promotionId',
        label: '优惠活动',
        type: 'select',
        items: [],
        itemProp: {
          label: 'name',
          value: 'id'
        },
        remote: true,
        loading: false,
        remoteMethod: name => {
          if (name.length >= 1) {
            this.searchItems[0].loading = true;
            this.$api.business.ridePromotion.getList({
              page: 1,
              size: 20,
              name: name
            }).then(res => {
              if (res.code === 200) {
                this.searchItems[0].items = res.data.records;
              }
            }).finally(() => {
              this.searchItems[0].loading = false;
            });
          }
        }
      }, {
        prop: 'customerPhone',
        label: '车主电话'
      }, {
        prop: 'status',
        label: '状态',
        type: 'select',
        items: ['未使用', '已使用']
      }, {
        prop: 'time',
        propItems: ['beginTime', 'endTime'],
        label: '发放时间',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD',
        defaultValue: [new Date().format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')],
        shortcuts: this.defaultDateShortcuts()
      }],
      columns: [{
        label: '活动名称',
        prop: 'name'
      }, {
        label: '车主电话',
        prop: 'phone'
      }, {
        label: '生效时间',
        prop: 'beginTime',
        width: '170px'
      }, {
        label: '失效时间',
        prop: 'endTime',
        width: '170px'
      }, {
        label: '发放时间',
        prop: 'grantTime',
        width: '170px'
      }, {
        label: '优惠券金额',
        prop: 'money',
        width: '80px'
      }, {
        label: '使用端',
        prop: 'employ'
      }, {
        label: '状态',
        prop: 'status'
      }],
      topButtons: []
    };
  },
  methods: {}
};